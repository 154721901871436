<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-sm-0 mb-1">
        إحصائيات الخدمات الرئيسية
      </b-card-title>
    </b-card-header>

    <b-card-body>
       <b-row>
        <b-col>
         
         
      <vue-apex-charts
     
        height="600"
             :options="radialBarChart.chartOptions"
        :series="radialBarChart.series"
      />
      </b-col>
      </b-row>
  
    <b-row>
        <b-col
          v-for="(item,index)  in data"
          :key="index"
          xl="3"
          sm="6"
         
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-info"
              >
                <feather-icon
                  size="24"
                  icon="DatabaseIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                <label>
                {{ item.main_service_name }}
                </label>
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.persons_count }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
        </b-card-body>
  </b-card>
  
</template>

<script>
import {
  BCard, BCardTitle, BCardBody, BRow, BCol, BCardHeader,  BMedia, BMediaAside, BAvatar, BMediaBody,BCardText
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
  BCardBody, BRow, BCol,
    BCardHeader,  BMedia, BMediaAside, BAvatar, BMediaBody,
    BCardText
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
    
   
     radialBarChart: {
    series: [],
    chartOptions: {
            chart: {
            type: 'donut',
             toolbar: {
      show: true
    }}
,     
      plotOptions: {
        radialBar: {
          size: 185,
          hollow: {
            size: '25%',
          },
          track: {
            margin: 15,
          },
          dataLabels: {
            name: {
              fontSize: '2rem',
              fontFamily: 'Montserrat',
            },
            value: {
              fontSize: '1rem',
              fontFamily: 'Montserrat',
            },
           
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      stroke: {
        lineCap: 'round',
      },
      labels: [],
    },
  },
 

    }
  },

  watch:{
  'data':function(){
    let label=[]
  
    this.data.forEach(el => {
       if(el.main_service_name !=null){
      this.radialBarChart.series.push(parseInt(el.persons_count))
      // console.log("his.radialBarChart.series")
      // console.log(this.radialBarChart.series)
   label.push(el.main_service_name );}
      
    });
  
    this.radialBarChart.chartOptions={labels:label}
           
 

  }
}
};
</script>
<style scoped>
.col-sm-6.col-xl-3 {
    padding: 13px;
}
</style>

