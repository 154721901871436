<template>
  <b-card
    v-if="data"
    no-body
  >
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <b-card-title>أعداد المستفيدين في كل ناحية </b-card-title>

      <!-- <b-dropdown
        no-caret
        right
        text="اختر محافظة"
        variant="transparent"
        class="chart-dropdown"
        toggle-class="p-50"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in data.lastDays"
          :key="day"
        >
         السويداء
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>
    <!--/ title and dropdown -->

    <b-card-body>
      <b-row>

        <!-- chart -->
        <b-col>

          <!-- apex chart -->
          <vue-apex-charts

            height="500"
            :options="chartOptions"
            :series="chartOptions.series"
          />
        </b-col>
        <!--/ chart -->
      </b-row>

      <b-row>
        <b-col
          v-for="(item,index) in data"
          :key="index"
          xl="3"
          sm="6"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-primary"
              >
                <feather-icon
                  size="24"
                  icon="UsersIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                <label>
                  {{ item.town_name }}
                </label>
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.persons_count }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BRow, BCol, BCardText, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      label: [],
      colors: ['#ffe700', '#a4f8cd', '#60f2ca', '#2bdac7', '#00d4bd', '#826bf8', '#2b9bf4', '#FFA1A1', '#a4f8cd', '#60f2ca', '#2bdac7', '#826bf8', '#2b9bf4', '#FFA1A1'],

      chartOptions: {
        series: [],
        chart: {
          type: 'pie',
          toolbar: {
            show: true,
          },

        },
        dataLabels: {
          enabled: false,
        },

        plotOptions: {
          pie: {

            donut: {
              labels: {
                show: true,

              },
            },
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        }],
      },

    }
  },

  watch: {
    data() {
      const label = []

      this.data.forEach(el => {
        if (el.town_name != null) {
          this.chartOptions.series.push(parseInt(el.persons_count))
          label.push(el.town_name)
        }
      })

      this.chartOptions = { labels: label }
    },
  },
}
</script>

<style scoped>
.col-sm-6.col-xl-3 {
    padding: 13px;
}
</style>
